import { capFirst, picture } from '#js/components/utils'
import { msg, str, updateWhenLocaleChanges } from '@lit/localize'
import { TrackingMixin } from '#js/components/TrackingMixin'
import { getUrlWithTracking } from '#js/components/utm'
import { html } from 'lit'
import { setLocale } from '#js/components/lit-i18n'
import { unsafeHTML } from 'lit/directives/unsafe-html.js'

export class BookingOption extends TrackingMixin {
  static get properties () {
    return {
      bookingOption: { type: Object }
    }
  }

  constructor () {
    super()
    setLocale(window.language)
    updateWhenLocaleChanges(this)
  }

  csrfToken = document.querySelector('input[name=csrfmiddlewaretoken]').value

  render () {
    return html`
      <link rel="stylesheet" href="${window.styleFilePath}"/>
      <div class="booking-option">
        ${this.getDateDisplay()}
        <div class="booking-option__content">
          ${this.getHostPicture()}
          <div class="booking-option__detail">
            ${this.getTimeDisplay()}
            <div>
              <h5 style="font-weight: 500;">
                ${this.getTitle()}
              </h5>
              ${this.getDescription()}
            </div>
          </div>
          <div class="booking-option__actions">
            ${this.getDraftRibbon()}
            <div class="booking-option__action">
              ${this.getButton()}
            </div>
          </div>
        </div>
      </div>
    `
  }

  getHostPicture () {
    if (this.bookingOption.host_is_featured && this.bookingOption.host_image) {
      return html`
        <a href="${this.bookingOption.host_url}" class="picture__wrapper">
          ${unsafeHTML(picture({
            picture: this.bookingOption.host_image,
            altText: this.bookingOption.host_title,
            ratio: '1/1',
            imgStyle: '',
            imgClass: 'picture'
          }))}
        </a>
      `
    }
  }

  getTitle () {
    if (this.bookingOption.host_is_featured) {
      return msg(html`${this.bookingOption.title} with <a href="${this.bookingOption.host_url}">${this.bookingOption.host_title.replace(' ', '\xa0')}</a>`)
    } else {
      return this.bookingOption.title
    }
  }

  getDateDisplay () {
    if (this.bookingOption.start_date) {
      return html`
        <div class="booking-option__date">
            <h3 class="booking-option__date">${this.bookingOption.start_date}</h3>
        </div>
      `
    }
  }

  getTimeDisplay () {
    if (this.bookingOption.start_time && this.bookingOption.end_time) {
      return html`<h4 class="booking-option__time">${this.bookingOption.start_time}&nbsp;&ndash;&nbsp;${this.bookingOption.end_time}</h4>`
    }
  }

  getDescription () {
    if (this.bookingOption.description !== '') {
      return html`
        <p>${unsafeHTML(this.bookingOption.description)}</p>
      `
    }
  }

  getButton () {
    if (this.bookingOption.latest_booking_url) {
      return html`
        <a href="${getUrlWithTracking(this.bookingOption.latest_booking_url, this.trackingInformation)}" class="button button--text">
            ${msg(str`show booking`)}
        </a>
      `
    } else if (this.bookingOption.is_fully_booked) {
      return html`
        <button class="button button--text button--disabled" disabled>
          ${msg(str`fully booked`)}
        </button>
      `
    } else if (parseFloat(this.bookingOption.retail_price)) {
      return html`
        ${this.bookingOption.retail_price.toLocaleString(window.language, { minimumFractionDigits: 2 })}&nbsp;&euro;
        <a href="${getUrlWithTracking(this.bookingOption.booking_url, this.trackingInformation)}" class="button button--tonal">
          ${msg(str`non-binding inquiries`)}
        </a>
      `
    } else if (this.bookingOption.instant_booking_url) {
      return html`
        <form action="${getUrlWithTracking(this.bookingOption.instant_booking_url, this.trackingInformation)}" method="POST" style="width: 100%">
          <input type="hidden" name="csrfmiddlewaretoken" value="${this.csrfToken}">
          <input type="submit" class="button button--tonal" value="${capFirst(this.bookingOption.book_display)}" @click="${this.disableSubmit}">
        </form>
      `
    } else {
      return html`
        <a href="${getUrlWithTracking(this.bookingOption.booking_url, this.trackingInformation)}" class="button button--tonal">
          ${capFirst(this.bookingOption.book_display)}
        </a>
      `
    }
  }

  disableSubmit (event) {
    event.target.disabled = true
    event.target.form.submit()
  }

  getDraftRibbon () {
    if (this.bookingOption.status === 'drafted') {
      return html`
        <div class="booking-option__action">
          <div class="ribbon ribbon--booking-option">
            ${msg(str`draft`)}
          </div>
        </div>
      `
    }
  }
}

window.customElements.define('booking-option', BookingOption)
